import {React,useState,useEffect} from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './c-d-v-c-i.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Papa from "papaparse";
import temp from "./../temp.csv";

const CDVCI = (props) => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [cyptoK,setCytoK]=useState([]);
  const [diseaseK,setDiseaseK]=useState([]);
  const [cytokine, setCytokine] = useState("none");
  const [disease, setDisease] = useState("none");
  const defaultOption = "none";
  useEffect(() => {
    Papa.parse(temp, {
      download: true,
      complete: function (input) {
        let cytoOptions=new Set();
        cytoOptions.add("none");
        cytoOptions.add("all");
        let diseaseOptions=new Set();
        diseaseOptions.add("none");
        diseaseOptions.add("all");
      let tempData=input.data;
        columns=tempData.shift();
        tempData.forEach((row)=>{
          cytoOptions.add(row[0]);
          diseaseOptions.add(row[1]);
        })
        setData(tempData);
        // setSelectedData(tempData);
        setCytoK(Array.from(cytoOptions));
        setDiseaseK(Array.from(diseaseOptions));
        //  records = input.data;
        //  console.log(records)
    }
});
}, []);
  // handleParse()
  useEffect(() => {filterData()}, [cytokine,disease]);
  let filterData=()=>{
    console.log(cytokine)
    console.log(disease)
    console.log(data.length)
    let tempData=[];
    data.forEach((row)=>{
      if(cytokine==row[0]&&disease==row[1])
        tempData.push(row);
      else if(cytokine==row[0]&&disease=="all")
        tempData.push(row);
      else if(disease==row[1]&&cytokine=="all")
        tempData.push(row);
      else if(cytokine=="all"&&disease=="all")
        tempData.push(row);
    
    })
    setSelectedData(tempData);
    console.log(tempData);
  }
  let columns=["Cytokine","Disease","References","Uniprot"]
  return (
    <div className="c-d-v-c-i-container">
      <Helmet>
        <title>CDVCI</title>
        <meta
          property="og:title"
          content="CDVCI - Cytokine Database of Viral Infection and Cancer"
        />
      </Helmet>
      <section className="c-d-v-c-i-hero">
        <div className="c-d-v-c-i-menu">
          <div id="mobile-menu" className="c-d-v-c-i-mobile-navigation">
            <img
              alt="pastedImage"
              src="/playground_assets/logo.png"
              className="c-d-v-c-i-logo"
            />
            <div className="c-d-v-c-i-links">
              <span className="home-navlink1 Link">About</span>
              <span className="home-navlink2 Link">Search</span>
              <span className="home-navlink3 Link">Contact</span>
            </div>
            <div id="close-mobile-menu" className="c-d-v-c-i-close-mobile-menu">
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="c-d-v-c-i-icon"
              >
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="c-d-v-c-i-desktop-navigation">
            <nav className="c-d-v-c-i-centered">
              <div className="c-d-v-c-i-left">
                <Link to="/" className="c-d-v-c-i-navlink">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo.png"
                    className="c-d-v-c-i-logo1"
                  />
                </Link>
                <div className="c-d-v-c-i-links1">
                  <Link to="/about" className="c-d-v-c-i-navlink1 Link">
                    About
                  </Link>
                  <Link to="/c-d-v-c-i" className="c-d-v-c-i-navlink2 Link">
                    Search
                  </Link>
                  <Link to="/contact" className="c-d-v-c-i-navlink3 Link">
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="c-d-v-c-i-right">
                <div className="c-d-v-c-i-get-started">
                <a href={temp}>
                  <span className="c-d-v-c-i-text03">Download</span>
                  </a>
                </div>
                <div id="open-mobile-menu" className="c-d-v-c-i-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/pastedimage-yxbd.svg"
                    className="c-d-v-c-i-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
            ></DangerousHTML>
          </div>
        </div>
        <header className="c-d-v-c-i-header">
          <h1 className="c-d-v-c-i-text04">
            Cytokine Database of Viral and Cancer Infection
          </h1>
          <p className="home-text05">
            <span>
            The cytokine database of viral infections and cancers is a collection of information about the roles of cytokines in these diseases. 
            Cytokines are small proteins that play important roles in immune response, inflammation, and cell signaling.
            </span>
            <br></br>
            <span>
            In viral infections, cytokines can help control viral replication and spread, but can also cause tissue damage and contribute to disease pathology. 
            In cancers, cytokines can promote tumor growth and invasion, and can also influence the immune response to cancer cells. 
            The information in the cytokine database can be used to develop new diagnostic tools, treatments, and vaccines for these diseases.
            </span>
          </p>
        </header>
      </section>
      <div className="c-d-v-c-i-banner-advanced-analytics">
        
        
      <div style={{"display":"block"}}>
        <div style={{"width":"30vw","margin":"auto"}}>
          <div className="c-d-v-c-i-text09" >Select Cytokine</div>
      <Dropdown options={cyptoK} onChange={(option)=>{
        console.log(option)
        setCytokine(option.value)
        

      }} value={defaultOption} placeholder="Select an option" />
      <br></br>
      <div className="c-d-v-c-i-text10" >Select Disease</div>
      <Dropdown options={diseaseK} onChange={(option)=>{setDisease(option.value)
      // filterData()
    }} value={defaultOption} placeholder="Select an option" />
      </div>
    <br></br>
      </div>
      <div className='tableContainer' >
              <table >
                <thead  >
                  <tr>
                    {columns.map((column,columnId) => <th key={columnId}>{column}</th>)}
                    </tr>

                  </thead>
                  <tbody key={cytokine+disease} >
                    {selectedData.length>0&&selectedData.map((row,rowId) => <tr key={rowId}>
                      <td>{row[0]}</td>
                      <td>{row[1]}</td>
                      <td >
                        <u>
                      <a href={row[4]}>
                        Link
                        </a>
                        </u>
                        </td>
                      <td >
                        <a href={row[5]} >
                          <u>
                        {row[6]}
                        </u>
                      </a>
                      </td>

                    </tr>)}
                    </tbody>
              </table>
              </div>
      </div>
      
      <header data-role="Footer" className="c-d-v-c-i-footer">
        <img
          alt="logo"
          src="/playground_assets/logo.png"
          className="c-d-v-c-i-image"
        />
        <div className="c-d-v-c-i-nav">
          <NavigationLinks rootClassName="rootClassName20"></NavigationLinks>
        </div>
        <div className="c-d-v-c-i-btn-group">
          {/* <a
            href="https://www.linkedin.com/in/aryasoni/"
            target="_blank"
            rel="noreferrer noopener"
            className="c-d-v-c-i-register button"
          >
            Develop By Arya Soni
          </a> */}
        </div>
        <div data-role="MobileMenu" className="c-d-v-c-i-mobile-menu">
          <div className="c-d-v-c-i-nav1">
            <div className="c-d-v-c-i-container1">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="c-d-v-c-i-image1"
              />
              <div data-role="CloseMobileMenu" className="c-d-v-c-i-menu-close">
                <svg viewBox="0 0 1024 1024" className="c-d-v-c-i-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks rootClassName="rootClassName21"></NavigationLinks>
          </div>
          <div>
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="c-d-v-c-i-icon04"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="c-d-v-c-i-icon06"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="c-d-v-c-i-icon08"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
  )
}

export default CDVCI
