import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Testimonal from '../components/testimonal'
import NavigationLinks from '../components/navigation-links'
import './home.css'
import temp from "./../temp.csv"

import { ObjViewer } from 'react-obj-viewer'


const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>CDVIC BTP NSUT'23</title>
        <meta property="og:title" content="Cytokine Database of Viral Infection and Cancer" />
      </Helmet>
      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="pastedImage"
              src="/playground_assets/logo.png"
              className="home-logo"
            />
            <div className="home-links">
              <span className="home-navlink1 Link">About</span>
              <span className="home-navlink2 Link">Search</span>
              <span className="home-navlink3 Link">Contact</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <Link to="/" className="home-navlink">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo.png"
                    className="home-logo1"
                  />
                </Link>
                <div className="home-links1">
                  <Link to="/about" className="home-navlink1 Link">
                    About
                  </Link>
                  <Link to="/c-d-v-c-i" className="home-navlink2 Link">
                    Search
                  </Link>
                  <Link to="/contact" className="home-navlink3 Link">
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="home-right">
                <div className="home-get-started">
                <a href={temp}>
                  <span className="home-text03">Download</span>
                  </a>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
                      /*
                      Mobile menu - Code Embed
                      */

                      // Mobile menu
                      const mobileMenu = document.querySelector("#mobile-menu")

                      // Buttons
                      const closeButton = document.querySelector("#close-mobile-menu")
                      const openButton = document.querySelector("#open-mobile-menu")

                      // On openButton click, set the mobileMenu position left to -100vw
                      openButton.addEventListener("click", function() {
                          mobileMenu.style.transform = "translateX(0%)"
                      })

                      // On closeButton click, set the mobileMenu position to 0vw
                      closeButton.addEventListener("click", function() {
                          mobileMenu.style.transform = "translateX(100%)"
                      })
                      </script>`}
            ></DangerousHTML>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text04">
            Cytokine Database of Viral Infections and Cancers
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </h1>
          <p className="home-text05">
            <span>
            The cytokine database of viral infections and cancers is a collection of information about the roles of cytokines in these diseases. 
            Cytokines are small proteins that play important roles in immune response, inflammation, and cell signaling.
            </span>
            <br></br>
            <span>
            In viral infections, cytokines can help control viral replication and spread, but can also cause tissue damage and contribute to disease pathology. 
            In cancers, cytokines can promote tumor growth and invasion, and can also influence the immune response to cancer cells. 
            The information in the cytokine database can be used to develop new diagnostic tools, treatments, and vaccines for these diseases.
            </span>
          </p>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <ObjViewer
              model="/playground_assets/1IOB.PDB-1A8M.PDB-4MHL.PDB.obj"
              width={900}
              height={600}
              border-radius={2}
              axis={true}
              boundingBox={true}
              color={0xffffff}
            />
            {/* <img
              alt="pastedImage"
              src="/playground_assets/review%20of%20literature-1200w-1400w.png"
              loading="lazy"
              className="home-image"
            /> */}
          </div>
        </div>
      </section>
      <section className="home-features">
        <div className="home-title">
          {/* <span className="home-text09">
            <span>
              Let&apos;s grow your social media
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>presence</span>
          </span>
          <span className="home-text13">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>eiusmod tempor incididunt.</span>
          </span> */}
        </div>
        <div className="home-cards"></div>
      </section>
      <section className="home-statistics">
        <div className="home-container1">
          <div className="home-active-users metrics">
            <span className="home-text16">1500</span>
            <span className="home-text17">
              <span>— Research Papers</span>
              <br></br>
            </span>
          </div>
          <div className="home-active-users1 metrics">
            <span className="home-text20">
              <span>112</span>
              <br></br>
            </span>
            <span className="home-text23">
              <span>— Cytokines</span>
              <br></br>
            </span>
          </div>
          <div className="home-active-users2 metrics">
            <span className="home-text26">
              <span>20</span>
              <br></br>
            </span>
            <span className="home-text29">
              <span>— Cancer</span>
              <br></br>
            </span>
          </div>
          <div className="home-active-users3 metrics">
            <span className="home-text32">
              <span>19</span>
              <br></br>
            </span>
            <span className="home-text35">
              <span>— Viral Infection</span>
              <br></br>
            </span>
          </div>
        </div>
      </section>
      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container2">
            <div className="home-left1">
              <span className="home-text39 title">
                Potential predictors of Cancers
              </span>
              <span className="home-text40">
                Cytokines are signaling molecules that play a key role in the immune system's response to cancer. Abnormal cytokine production and activity may contribute to cancer development and progression, and measuring cytokine levels may serve as a potential predictor of cancer risk and prognosis
              </span>
            </div>
            <div className="home-image-container">
              <img
                alt="pastedImage"
                src="/playground_assets/4.png"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
        <div className="home-banner-advanced-analytics">
          <div className="home-centered-container">
            <div className="home-image-container1">
              <img
                alt="pastedImage"
                src="/playground_assets/review%20of%20literature-1200w-1400w.png"
                className="home-cards-image1"
              />
            </div>
            <div className="home-right1">
              <h2 className="home-text43 title">
                DATA EXTENSIVE STUDY              </h2>
              <div className="home-category">
                <span className="home-text45">
                Data extensive study is done, including a collection of 1600 papers for 40 infections including 20 cancers and 20 viral infections. Total of 80 keywords were used for the required data assemblage.
                </span>
              </div>
              <div className="home-category1">
                <span className="home-text48">
                A comprehensive set of information on a wide range of infections, including both viral infections and cancers are considered. This information can be used to better understand these diseases and develop strategies to prevent and treat them.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner">
          <div className="home-container3">
            {/* <div className="home-left2">
              <span className="home-text50">Improve Scheduling</span>
              <h2 className="home-text51 title">
                Powerful scheduler that saves you time
              </h2>
              <span className="home-text52">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. vv
              </span>
            </div> */}
            <div className="home-left2">
              <img
                alt="pastedImage"
                src="/playground_assets/5.jpeg"
                className="home-cards-image2"
              />
            </div>
            <div className="home-left2">
              <img
                alt="pastedImage"
                src="/playground_assets/6.jpeg"
                className="home-cards-image2"
              />
            </div>
            <div className="home-left2">
              <img
                alt="pastedImage"
                src="/playground_assets/7.jpeg"
                className="home-cards-image2"
              />
            </div>
            <div className="home-left2">
              <img
                alt="pastedImage"
                src="/playground_assets/8.jpeg"
                className="home-cards-image2"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-how-it-works">
        <div className="home-centered-container1">
          <div className="home-heading">
            <span className="home-text53">Venn Diagram Analysis</span>
            <span className="home-text54 title">
              Overlap and Distinct Cytokine Expression in various diseases: Venn Diagram Analysis
            </span>
          </div>
          <div className="home-category2">
            <div className="home-container4">
              <img
                alt="pastedImage"
                src="/playground_assets/1.png"
                className="home-pasted-image"
              />
            </div>
          </div>
          <div className="home-row">
            <div className="home-category3">
              <img
                alt="pastedImage"
                src="/playground_assets/2.png"
                className="home-pasted-image1"
              />
            </div>
            <div className="home-category4">
              <img
                alt="pastedImage"
                src="/playground_assets/3.png"
                className="home-pasted-image2"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-testimonals">
        <div className="home-left3">
          {/* <span className="home-text55">Key Facts</span> */}
          <span className="home-text56 title">
          Cytokines in Viral Infection and Cancer : Key Facts
          </span>
        </div>
        <div className="home-right2">
          <div className="home-column">
            <Testimonal
              Quote='"The intracellular interleukin (IL)-10 and IL-12 status on monocytes in patients with advanced stomach cancer were significantly increased compared with those in patients with early disease or in healthy individuals.”'
              Name="Ye et al. 2022"
            ></Testimonal>
            {/* <Testimonal
              From="Social Club"
              Name="Dipeptidyl peptidase 4 (DPP4/CD26) was identified as the human receptor for MERS-CoV, and a variety of molecular and serological assays developed.”"
            ></Testimonal> */}
            <Testimonal
              Name="Mubarak et al. 2019"
              Quote="“MERS-CoV infection stimulates the production of type I IFNs (IFN-a and IFN-B) by infected cells, which leads to the release of some chemokines, such as MCP-1, CXCL10, and the cytokine IL10, which are responsible for T cell recruitment.”"
            ></Testimonal>
            {/* <Testimonal
              From="Hello W."
              Name="Laraine Summers"
              Quote="“Rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.”"
            ></Testimonal> */}
          </div>
          <div className="home-column1">
            <Testimonal
              Quote='"The early stages of infection with wild-type measles virus are highlighted by secretions of TNF and IFNy”'
              Name="Rabaan et al. 2022"
            ></Testimonal>
            <Testimonal
              Name="Rabaan et al. 2022"
              Quote="“Elevated levels of IL-4 and IL-10 cytokines are seen in the later stages of infection.”"
            ></Testimonal>
            <Testimonal
              Name="Dawson et al. 2019"
              Quote='"Dipeptidyl peptidase 4 (DPP4/CD26) was identified as the human receptor for MERS-CoV, and a variety of molecular and serological assays developed.”'
            ></Testimonal>
            {/* <Testimonal
              From="Zigo"
              Name="Mary Pau"
              Quote='"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ”'
            ></Testimonal> */}
          </div>
        </div>
      </section>

      <header data-role="Footer" className="home-footer">
        <img
          alt="logo"
          src="/playground_assets/logo.png"
          className="home-image1"
        />
        <div className="home-nav">
          <NavigationLinks rootClassName="rootClassName18"></NavigationLinks>
        </div>
        <div className="home-btn-group">
          {/* <a
            href="https://www.linkedin.com/in/aryasoni/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-register button"
          >
            Develop By Arya Soni
          </a> */}
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-nav1">
            <div className="home-container5">
              <img
                alt="image"
                src="/playground_assets/logo.png"
                className="home-image2"
              />
              <div data-role="CloseMobileMenu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks rootClassName="rootClassName19"></NavigationLinks>
          </div>
          <div>
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Home
