import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './contact.css'
import temp from "./../temp.csv"

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact</title>
        <meta
          property="og:title"
          content="Contact - Cytokine Database of Viral Infection and Cancer"
        />
      </Helmet>
      <section className="contact-hero">
        <div className="contact-menu">
          <div id="mobile-menu" className="contact-mobile-navigation">
            <img
              alt="pastedImage"
              src="/playground_assets/logo.png"
              className="contact-logo"
            />
            <div className="contact-links">
              <span className="home-navlink1 Link">About</span>
              <span className="home-navlink2 Link">Search</span>
              <span className="home-navlink3 Link">Contact</span>
            </div>
            <div id="close-mobile-menu" className="contact-close-mobile-menu">
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="contact-icon"
              >
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="contact-desktop-navigation">
            <nav className="contact-centered">
              <div className="contact-left">
                <Link to="/" className="contact-navlink">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo.png"
                    className="contact-logo1"
                  />
                </Link>
                <div className="contact-links1">
                  <Link to="/about" className="contact-navlink1 Link">
                    About
                  </Link>
                  <Link to="/c-d-v-c-i" className="contact-navlink2 Link">
                    Search
                  </Link>
                  <Link to="/contact" className="contact-navlink3 Link">
                    Contact us
                  </Link>
                </div>
              </div>
              <div className="contact-right">
                <div className="contact-get-started">
                <a href={temp}>
                  <span className="contact-text03">Download</span>
                  </a>
                </div>
                <div id="open-mobile-menu" className="contact-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/pastedimage-yxbd.svg"
                    className="contact-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
            ></DangerousHTML>
          </div>
        </div>
        <div className="contact-cards">
          <div className="contact-container1">
            <div className="card">
              <span className="contact-text04">
                <span>Ms. Akanksha Kulshreshtha</span>
                <br></br>
              </span>
              <span className="contact-text07">
                <span>Assistant Professor</span>
                <br></br>
                <span>Department of Biological Sciences and Engineering</span>
                <br></br>
                <span>Netaji Subhas University of Technology</span>
                <br></br>
                <span>New Delhi, India</span>
                <br></br>
                <br></br>
                <span>akankshak@nsut.ac.in</span>
              </span>
            </div>
            <div className="contact-publish card">
              <span className="contact-text18">Ishita Singhal</span>
              <span className="contact-text19">
                <span>Student Member</span>
                <br></br>
                <span>B.E. Biotechnology</span>
                <br></br>
                <span>Batch of 2023</span>
                <br></br>
                <br></br>
                <span>
                  ishitasinghal30@gmail.com
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="contact-container2">
            <div className="card contact-analyze">
              <span className="contact-text29">Shubhangi Gupta</span>
              <span className="contact-text30">
                <span>Student Member</span>
                <br></br>
                <span>B.E. Biotechnology</span>
                <br></br>
                <span>Batch of 2023</span>
                <br></br>
                <br></br>
                <span>
                  shubhigupta991@gmail.com
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
            </div>
            <div className="card">
              <span className="contact-text40">Arya Soni</span>
              <span className="contact-text41">
                <span>Student Member</span>
                <br></br>
                <span>B.E. Biotechnology</span>
                <br></br>
                <span>Batch of 2023</span>
                <br></br>
                <br></br>
                <span>aryasoni98@gmail.com</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </section>
      <header data-role="Footer" className="contact-footer">
        <img
          alt="logo"
          src="/playground_assets/logo.png"
          className="contact-image"
        />
        <div className="contact-nav">
          <NavigationLinks rootClassName="rootClassName10"></NavigationLinks>
        </div>
        <div className="contact-btn-group">
          {/* <a
            href="https://www.linkedin.com/in/aryasoni/"
            target="_blank"
            rel="noreferrer noopener"
            className="contact-register button"
          >
            Develop By Arya Soni
          </a> */}
        </div>
        <div data-role="MobileMenu" className="contact-mobile-menu">
          <div className="contact-nav1">
            <div className="contact-container3">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="contact-image1"
              />
              <div data-role="CloseMobileMenu" className="contact-menu-close">
                <svg viewBox="0 0 1024 1024" className="contact-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks rootClassName="rootClassName11"></NavigationLinks>
          </div>
          <div>
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="contact-icon04"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="contact-icon06"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="contact-icon08"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Contact
